<template>
  <div class="assignment">
    <div class="column" style="min-height:calc(100vh - 175px); margin-bottom: 0;">
      <div>
        <el-steps :active="stepActive" align-center process-status="finish" finish-status="process">
          <el-step title="因子赋值" :description="stepActive==0?'进行中':'步骤一'"></el-step>
          <el-step title="市场监测" :description="stepActive==1?'进行中':'步骤二'"></el-step>
          <el-step title="提交"  :description="stepActive==2?'进行中':'步骤三'"></el-step>
        </el-steps>
      </div>
      <div class="flex-row justify-between align-items-center border-bottom padding-tb-10 margin-top-10">
        <div class="flex-row align-items-center">
          <div v-if="stepActive>0" class="margin-right-20"><el-button size="small" @click="stepActive-=1">上一步</el-button></div>
          <div v-if="targetInfo">
            <el-popover
              placement="bottom-start"
              width="500"
              trigger="hover">
              <div class="font-size-12 line-height-large">
                <div><span class="color-sub">数据名称：</span>{{ targetInfo.desc.name }}</div>
                <div><span class="color-sub">数据单位：</span>{{ targetInfo.desc.unit }}</div>
                <div><span class="color-sub">市场名称：</span>{{ targetInfo.desc.market }}</div>
                <div><span class="color-sub">规格牌号：</span>{{ targetInfo.desc.dataModel }}</div>
                <div><span class="color-sub">更新频率：</span>{{ targetInfo.desc.frequency }}</div>
                <div><span class="color-sub">统计日期：</span>{{ targetInfo.desc.time }}</div>
                <div><span class="color-sub">样本说明：</span>{{ targetInfo.desc.explain }}</div>
                <div><span class="color-sub">方法论：</span>{{ targetInfo.desc.func }}</div>
                <div><span class="color-sub">数据来源：</span>{{ targetInfo.desc.dataSource }}</div>
              </div>
              <span slot="reference"><span class="color-sub">预测目标：</span>{{targetInfo.targetName}}</span>
            </el-popover>
          </div>
        </div>
        <div class="flex-row align-items-center">
          <template v-if="stepActive==1">
            <el-button size="small" @click="dimensionVisible=true" v-if="$store.state.loginRes.moduleList.find(v=>{return v.moduleCode=='she_zhi_jian_ce_wei_du'})">设置监测维度</el-button>
            <div class="border-right padding-left-20 margin-right-20">&nbsp;</div>
          </template>
          <el-button size="small" type="primary" v-if="stepActive<2" @click="stepActive+=1">下一步</el-button>
          <template v-if="stepActive==2">
            <span class="margin-right-10" v-if="lastSubmissionInfo">上次提交时间：{{lastSubmissionInfo.createTime}}</span>
            <el-button size="small" type="primary" :loading="publishLoading" @click="publish">提交</el-button>
          </template>
          
        </div>
      </div>
      <div v-if="stepActive==0">
        <el-table
          ref="mainTable"
          :key="productId+'-'+modelFreq+'-'+$root.docWidth"
          :height="$root.docHeight-315"
          size="medium"
          v-loading="divisors.loading"
         :data="divisors.items">
          <div slot="empty"><el-empty :image-size="150"></el-empty></div>
          <el-table-column
            min-width="200"
            fixed="left"
            :show-overflow-tooltip="false"
            label="因子名称">
            <template slot-scope="props">
              <el-popover placement="right-start" width="350" trigger="hover">
                <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">
                  <div v-for="(item,index) in props.row.children" :key="index" :class="{'padding-top-10':index>0,'margin-top-10':index>0,'border-top':index>0}">
                      <div><span class="color-sub">数据名称：</span>{{item.factorName}}</div>
                      <div><span class="color-sub">数据单位：</span>{{item.unit}}</div>
                      <div><span class="color-sub">数据类型：</span>{{item.typeName}}</div>
                      <div><span class="color-sub">更新频率：</span>{{item.modelFreq}}</div>
                      <div><span class="color-sub">统计日期：</span>{{item.startDate}}-{{item.endDate}}</div>
                      <div><span class="color-sub">方法论：</span>{{item.func}}</div>
                      <div><span class="color-sub">数据来源：</span>{{item.dataSource}}</div>
                  </div>
                </div>
                <span slot="reference" class="display-inline-block">
                  <span class="text-overflow-ellipsis-block" @click="getDivisorChart(props.row)">
                    <span class="custom-tag margin-right-10" color="red" size="mini" v-if="props.row.isOut">外生</span>
                    <span class="link-text">{{props.row.divisorName}}</span>
                  </span>
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="单位" prop="unit">
            <template slot-scope="props">{{ props.row.unit||"-" }}</template>
          </el-table-column>
          <el-table-column v-for="(item,index) in divisors.dataList" :key="index" :label="item.dateCycleShow"  min-width="110">
            <template slot-scope="props">
              <div class="flex-row align-items-center">
                <span v-if="index>0&&item[props.row.id].valueType!=1&&numWarning(divisors.dataList[index-1][props.row.id].value||props.row.hisValue,item[props.row.id].value,props.row.hisRide)" class="dot margin-right-5"></span>
                <el-tooltip :disabled="!(index>0&&item[props.row.id].valueType!=1&&numWarning(divisors.dataList[index-1][props.row.id].value||props.row.hisValue,item[props.row.id].value,props.row.hisRide))" content="较前值的涨跌幅超过10%" placement="top">
                  <span :class="{'color-sub':item[props.row.id].valueType==1}">{{ item[props.row.id].value | numFormat(2,'-')}}</span>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="赋值说明"
            :show-overflow-tooltip="true"
            prop="reason"
            min-width="200"
            fixed="right"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            prop="reason"
            width="50"
            fixed="right"
          >
            <template slot-scope="props">
             <span v-if="!props.row.isOut"  class="link-text text-nowrap" @click="getDivisorAdjustInfo(props.row)">调整</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="stepActive==1" class="flex-row justify-center"  style="min-height:calc(100vh - 315px);" v-loading="marketDimension.loading">
        <template v-if="marketDimension.list.length">
          <div v-for="(dateItem,dateIndex) in marketDimension.list[0].children[0].dataList" :key="dateIndex" :class="{'flex-auto':true,'padding-20':true,'border-left':dateIndex>0}">
            <div>{{dateItem.forecastDateShow}}监测</div>
            <div v-for="(firstItem,firstIndex) in  marketDimension.list" :key="firstIndex">
              <div class="margin-top-10" v-for="(secondItem,secondIndex) in firstItem.children" :key="secondIndex">
                <div class="flex-row align-items-center justify-between">
                  <div>
                    <span>{{firstItem.firstNode}}</span>
                    <span v-if="firstItem.firstNode!=secondItem.secondNode||firstItem.children.length>1"> - {{ secondItem.secondNode }}</span>
                  </div>
                  <div class="font-size-20 color-blue margin-left-5 hover-opacity" @click="addNodeDesc([firstIndex,secondIndex,dateIndex])"><i class="el-icon-circle-plus-outline"></i></div>
                </div>
                <div class="flex-row margin-top-10" v-for="(nodeItem,nodeIndex) in secondItem.dataList[dateIndex].nodeList" :key="nodeIndex">
                  <el-input size="small" placeholder="请输入您监测到的事件" v-model="nodeItem.desc" @focus="activeNodeDesc([firstIndex,secondIndex,dateIndex,nodeIndex])" @keyup.enter.native="saveNodeDesc([firstIndex,secondIndex,dateIndex,nodeIndex],$event)" @blur="saveNodeDesc([firstIndex,secondIndex,dateIndex,nodeIndex])"></el-input>
                  <div class="font-size-20 color-red margin-left-5 hover-opacity" v-if="secondItem.dataList[dateIndex].nodeList.length>1&&!nodeItem.active" @click="delNodeDesc([firstIndex,secondIndex,dateIndex,nodeIndex])"><i class="el-icon-remove-outline"></i></div>
                </div>
              </div>
            </div>
            
          </div>
        </template>
        <template v-else>
          <el-empty :image-size="200"></el-empty>
        </template>
      </div>
      <div v-if="stepActive==2">
        <div class="margin-top-20 margin-bottom-10">因子赋值</div>
        <el-table
          :max-height="545"
          :key="productId+'-'+modelFreq+'-'+$root.docWidth"
          size="medium"
          v-loading="divisors.loading"
         :data="divisors.items">
          <div slot="empty"><el-empty :image-size="150"></el-empty></div>
          <el-table-column
            min-width="150"
            fixed="left"
            :show-overflow-tooltip="false"
            label="因子名称">
            <template slot-scope="props">
              <el-popover placement="right-start" width="350" trigger="hover">
                <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">
                  <div v-for="(item,index) in props.row.children" :key="index" :class="{'padding-top-10':index>0,'margin-top-10':index>0,'border-top':index>0}">
                    <div><span class="color-sub">数据名称：</span>{{item.factorName}}</div>
                    <div><span class="color-sub">数据单位：</span>{{item.unit}}</div>
                    <div><span class="color-sub">数据类型：</span>{{item.typeName}}</div>
                    <div><span class="color-sub">更新频率：</span>{{item.modelFreq}}</div>
                    <div><span class="color-sub">统计日期：</span>{{item.startDate}}-{{item.endDate}}</div>
                    <div><span class="color-sub">方法论：</span>{{item.func}}</div>
                    <div><span class="color-sub">数据来源：</span>{{item.dataSource}}</div>
                  </div>
                </div>
                <span slot="reference" class="display-inline-block">
                  <span class="text-overflow-ellipsis-block" @click="getDivisorChart(props.row)">
                    <span class="custom-tag margin-right-10" color="red" size="mini" v-if="props.row.isOut">外生</span>
                    <span class="link-text">{{props.row.divisorName}}</span>
                  </span>
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="单位" prop="unit"></el-table-column>
          <el-table-column v-for="(item,index) in divisors.dataList" :key="index" :label="item.dateCycleShow">
            <template slot-scope="props"><span :class="{'color-sub':item[props.row.id].valueType==1}">{{ item[props.row.id].value | numFormat(2,'-')}}</span></template>
          </el-table-column>
          <el-table-column
            label="赋值说明"
            :show-overflow-tooltip="true"
            prop="reason"
            min-width="150"
            fixed="right"
          >
          </el-table-column>
          
        </el-table>
        <div class="margin-top-20 margin-bottom-10">市场监测</div>
        <div class="MarketMonitoringTable-title">{{modelFreq=='week'?'周度':modelFreq=='month'?'月度':''}}市场基本面变动监测表</div>
        <MarketMonitoringTable :data="marketDimension.list"></MarketMonitoringTable>
      </div>
    </div>
    <!-- 编辑因子弹窗 -->
    <el-dialog custom-class="custom-dialog" :title="divisorActive.divisorName+'-赋值调整'" append-to-body :close-on-click-modal="false" width="50%" top="10vh" :visible.sync="divisorAdjustInfo.visible">
      <div style="height: calc(80vh - 180px); overflow: auto; padding: 0 10px;">
        <el-form :model="divisorAdjustInfo.form" ref="divisorForm" label-width="120px" :key="divisorAdjustInfo.visible" class="flex-row flex-wrap">
          <div v-for="(item,index) in divisorAdjustInfo.form.dataList" :key="index" style="width:50%;">
            <el-form-item v-if="item.valueType==1" :label="item.dateCycleShow" :prop="'dataList.'+index+'.hisValue'"
              :rules="[
                  { validator: validateNumber, trigger: ['blur','change'] }
                ]"
              >
                <el-input maxlength="10" v-model.trim="item.hisValue" autocomplete="off" placeholder="" :disabled="item.valueType==1"></el-input>
            </el-form-item>
            <el-popover
                v-else
                popper-class="opacity-80"
                placement="top-end"
                width="200"
                offset="30"
                trigger="hover"
            >
              <div>
                <div>卓创预测：{{item.sciValue | numFormat(2,'-')}}</div>
                <template v-if="modelFreq=='week'">           
                  <div>AI预测：{{item.divisorRes4 | numFormat(2,'-')}}</div>
                </template>
                <template v-else>
                  <div>AI预测移动1期：{{item.divisorRes1 | numFormat(2,'-')}}</div>
                  <div>AI预测移动3期：{{item.divisorRes3 | numFormat(2,'-')}}</div>
                  <div>AI预测移动12期：{{item.divisorRes12 | numFormat(2,'-')}}</div>
                </template>
              </div>
              <el-form-item slot="reference" :label="item.dateCycleShow" :prop="'dataList.'+index+'.befarValue'" 
              :rules="modelFreq=='week'&&divisorAdjustInfo.form.dataList.slice(index+1,divisorAdjustInfo.form.dataList.length).some(v=>{return v.befarValue})?[
                { required: true, message: '数值不能为空！', trigger: 'change' },
                { validator: validateNumber, trigger: ['blur','change'] }
              ]:[ { validator: validateNumber, trigger: ['blur','change'] }]">
                <el-input maxlength="10" v-model.trim="item.befarValue" placeholder="" autocomplete="off"></el-input>
              </el-form-item>
            </el-popover>
          </div>
          <el-form-item prop="reason" label="赋值说明" class="flex-auto" style="width:100%">
            <el-input maxlength="200"  show-word-limit v-model.trim="divisorAdjustInfo.form.reason" type="textarea" :rows="4" placeholder="请输入对价格影响的逻辑传导关系进行简要说明" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div>预测方法建议：{{ divisorAdjustInfo.form.suggestion }}</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="flex-row justify-center">
          <el-button @click="divisorAdjustInfo.visible=false">取消</el-button>
          <el-button type="primary" @click="submitDivisorAdjustForm('divisorForm')" :loading="divisorAdjustInfo.loading">保 存</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 因子数据图详情弹窗 -->
    <el-dialog custom-class="custom-dialog" :title="divisorActive.divisorName" append-to-body :close-on-click-modal="false" width="80%" top="15vh" :visible.sync="divisorData.visible">
      <div class="flex-column" style="height: calc(70vh - 80px);" v-loading="divisorData.loading">
        <div><span>卓创赋值说明：</span>{{divisorData.info.sciReason}}</div>
        <div class="margin-top-5"><span>滨化赋值说明：</span>{{divisorData.info.befarReason}}</div>
        <Chart :option="divisorChart" class="flex-auto"></Chart>
      </div>
    </el-dialog>
    <!-- 设置监测维度弹窗 -->
    <el-dialog custom-class="custom-dialog assignment-dialog" title="设置监测维度"  :visible.sync="dimensionVisible" :append-to-body="true" width="50%" top="10vh" :close-on-click-modal="false" :close-on-press-escape="false">
      <div style="height: calc(100vh - 20vh - 180px); overflow: auto; padding: 0 10px;"  v-loading="marketDimension.loading">
        <div class="flex-row padding-bottom-10 border-bottom">
          <el-input class="margin-right-10" size="small" maxlength="10" v-model.trim="addMarketDimension.firstNode" placeholder="请输入一级市场监测维度" @keyup.enter.native="saveFirstNode"></el-input>
          <el-button size="small" plain icon="el-icon-circle-plus-outline" :disabled="!addMarketDimension.firstNode" :loading="addMarketDimension.loading" @click="saveFirstNode">添加市场监测维度</el-button>
        </div>
        <div class="flex-row align-items-center justify-center" v-if="!marketDimension.list.length" style="height: calc(100vh - 20vh - 225px);">
          <el-empty :image-size="150"></el-empty>
        </div>
        <div class="padding-tb-10 border-bottom" v-for="(item,index) in marketDimension.list" :key="index">
          <div class="flex-row justify-between">
            <div>{{item.firstNode}}</div>
            <div class="link-text" @click="deleteFirstNode(index)">删除</div>
          </div>
          <div class="flex-row align-items-center flex-wrap">
            <div class="margin-right-10 margin-top-10" style="width: 160px;" v-for="(item2,index2) in item.children" :key="index2">
              <el-input size="small" placeholder="..." v-model="item2.secondNode" disabled v-if="!item2.canEdit">
                <template  slot="append">
                  <i class="el-icon-edit link" @click="editSecondNodeName([index,index2])"></i>
                  <i v-if="item.children.length>1" class="el-icon-close link margin-left-10" @click="deleteSecondNode([index,index2])"></i>
                </template>
              </el-input>
              <el-input size="small" placeholder="" v-model="item2.secondNodeCache" @keyup.enter.native="updateSecondNodeName([index,index2])" @blur="updateSecondNodeName([index,index2])" v-focus v-else></el-input>
            </div>
           
            <div class="margin-right-10 margin-top-10">
              <el-input v-if="item.addStatus" size="small" style="width: 160px;" placeholder="请输入标题" v-model="item.addSecondNodeCache" @keyup.enter.native="saveSecondNode(index)" @blur="saveSecondNode(index)" v-focus>
                <template  slot="append">
                  <span class="link" @click="saveSecondNode(index)">添加</span>
                </template>
              </el-input>
              <div class="font-size-20 color-blue hover-opacity" v-else @click="saveSecondNodeShow(index)"><i class="el-icon-circle-plus-outline"></i></div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="flex-row justify-center">
          <el-button size="medium" type="primary" @click="dimensionVisible=false">关闭</el-button>
        </div>
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
import Chart from "@/components/Chart";
import MarketMonitoringTable from "@/components/MarketMonitoringTable";
export default {
  name: 'Assignment',
  components:{
    Chart,MarketMonitoringTable,
  },
  props:{
    curProduct:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data (){
    return{
      stepActive:0,//step当前显示的步骤
      modelFreq:this.$route.params.modelFreq,
      productId:this.curProduct.productId,
      targetId:this.curProduct.targetId,

      targetInfo:null,//目标价信息

      divisors:{//因子赋值列表
        loading:false,
        items:[],
        dataList:[],
      },

      divisorActive:{},//当前编辑的因子
      divisorAdjustInfo:{//当前编辑的因子调整详情
        loading:false,
        visible:false,
        form:{}
      },

      divisorData:{//因子图表详情
        loading:false,
        visible:false,
        info:{}
      },
      
      marketDimension:{//市场维度监测列表
        loading:false,
        list:[]
      },
      dimensionVisible:false,//设置市场监测维度弹窗显示状态
      addMarketDimension:{
        loading:false,
        firstNode:''
      },

      lastSubmissionInfo:null,//最后提交返回信息
      publishLoading:false,//提交loading

      validateNumber:(rule, value, callback) => {
        //表单数字校验规则
        if (isNaN(value)) {
          callback(new Error('请输入数值类型！'));
        } else {
          callback();
        }
      },
    }
     
  },
  beforeCreate(){
    if(!['week','month'].some(val=>{return val==this.$route.params.modelFreq})){
      this.$router.replace({
          path: '/404',
      })
    }
  },
  created(){
    this.getData()
  },
  mounted(){
    try {
      let a=(null-2)/2
      console.log(a)
    } catch (error) {
      console.log('error')
    }
  },
  methods:{
    getData(){
      if(this.productId&&this.modelFreq){
        this.getTargetInfo()
        this.getDivisors()
        this.getDescList()
        this.getLastSubmissionInfo()
      }
    },
    //获取目标价信息
    getTargetInfo(){
      this.$instance.get('/index/targetTopInfo',{
          params: {
           ...this.$store.state.basicParams,
           targetId:this.targetId,
           modelFreq:this.modelFreq
          },
      })
      .then(res=>{
        if(res.data.code==0){
          this.targetInfo=res.data.info
        }else{
          this.targetInfo=null
        }
      })
      .catch(()=>{
        this.targetInfo=null
      })
    },
    //因子赋值=========================================================================
    //获取因子赋值列表
    getDivisors(){
      this.divisors.loading=true;
      this.$instance.get('/assignment/getAssignmentValue',{
          params: {
           ...this.$store.state.basicParams,
           productId:this.productId,
           modelFreq:this.modelFreq
          },
      })
      .then(res=>{
        this.divisors.loading=false;
        if(res.data.code==0){
          this.divisors.items=res.data.info.items||[]
          this.divisors.dataList=res.data.info.dataList||[]
          this.$nextTick(()=>{
            this.$refs.mainTable.doLayout()
          })
        }else{
          this.divisors.items=[]
          this.divisors.dataList=[]
        }
      })
      .catch(()=>{
        this.divisors.loading=false;
        this.divisors.items=[]
        this.divisors.dataList=[]
      })
    },
     //获取因子赋值调整详情
     getDivisorAdjustInfo(divisor){
      this.divisorActive=divisor
      this.divisors.loading=true;
      this.$instance.get('/assignment/getAdjustInfo',{
          params: {
            ...this.$store.state.basicParams,
            productId:this.productId,
            modelFreq:this.modelFreq,
            divisorId:divisor.divisorId,
            isOut:divisor.isOut,
          },
      })
      .then(res=>{
        this.divisors.loading=false;
        if(res.data.code==0){
          this.divisorAdjustInfo.form=res.data.info;
          this.divisorAdjustInfo.visible=true;
        }
      })
      .catch(()=> {
        this.divisors.loading=false;
      }); 
    },
    saveDivisorAdjustInfo(){
      this.divisorAdjustInfo.loading=true
      let requestData={
        ...this.$store.state.basicParams,
        modelFreq:this.modelFreq,
        divisorId:this.divisorAdjustInfo.form.divisorId,
        reason:this.divisorAdjustInfo.form.reason,
        dataList:this.divisorAdjustInfo.form.dataList.map(v=>{return {
          "month": v.dateCycle,
          "valueType": v.valueType,
          "value": v.valueType==1?v.hisValue:v.befarValue
        }}),
      }
      this.$instance.post('/assignment/saveBefarExpertValue',requestData)
      .then(res=>{
        this.divisorAdjustInfo.loading=false
        if(res.data.code==0){
          this.divisorAdjustInfo.form.dataList.forEach((v,index)=>{
            this.divisors.dataList[this.divisors.dataList.length-this.divisorAdjustInfo.form.dataList.length+index][this.divisorActive.divisorId]={valueType:v.valueType,value:v.valueType==1?v.hisValue:v.befarValue}
          })
          this.divisorActive.reason=this.divisorAdjustInfo.form.reason
          this.divisorAdjustInfo.visible=false
        }
      })
      .catch(()=>{
        this.divisorAdjustInfo.loading=false
      })
    },
    submitDivisorAdjustForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
           this.saveDivisorAdjustInfo();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //获取因子走势图详情
    getDivisorChart(divisor){
      this.divisorActive=divisor
      this.divisorData={
        loading:true,
        visible:true,
        info:{}
      }
      this.$instance.get('/assignment/getDivisorDetail',{
          params: {
            ...this.$store.state.basicParams,
            productId:this.productId,
            modelFreq:this.modelFreq,
            divisorId:divisor.divisorId,
            isOut:divisor.isOut,
          },
      })
      .then(res=>{
        this.divisorData.loading=false;
        if(res.data.code==0){
          this.divisorData.info=res.data.info;
        }else{
          this.divisorData.info={}
        }
      })
      .catch(()=> {
        this.divisorData.loading=false;
        this.divisorData.info={}
      }); 
    },
    //市场监测=========================================================================
    //获取市场监测维度列表
    getDescList(){
      this.marketDimension.loading=true;
      this.$instance.get('/assignment/getDescList',{
          params: {
           ...this.$store.state.basicParams,
           productId:this.productId,
           modelFreq:this.modelFreq
          },
      })
      .then(res=>{
        this.marketDimension.loading=false;
        if(res.data.code==0){
          this.marketDimension.list=res.data.info
        }else{
          this.marketDimension.list=[]
        }
      })
      .catch(()=>{
        this.marketDimension.loading=false;
        this.marketDimension.list=[]
      })
    },
     //市场维度添加一级节点
     saveFirstNode(){
      this.addMarketDimension.loading=true
      let requestData={
        ...this.$store.state.basicParams,
        productId:this.productId,
        modelFreq:this.modelFreq,
        nodeName:this.addMarketDimension.firstNode
      }
      this.$instance.post('/assignment/saveFirstNode',requestData)
      .then(res=>{
        this.addMarketDimension.loading=false
        if(res.data.code==0){
          this.addMarketDimension.firstNode=''
          this.getDescList()
        }
      })
      .catch(()=>{
        this.addMarketDimension.loading=false
      })
    },
    //市场维度添加二级节点
    saveSecondNodeShow(index){
      let item=this.marketDimension.list[index]
      this.$set(item,"addSecondNodeCache",'')
      this.$set(item,"addStatus",true)
    },
    saveSecondNode(index){
      let item=this.marketDimension.list[index]
      if(!item.addSecondNodeCache){
        // this.$message({message: '请输入市场监测维度二级标题',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      let requestData={
        ...this.$store.state.basicParams,
        productId:this.productId,
        modelFreq:this.modelFreq,
        nodeName:item.firstNode,
        secondNodeName:item.addSecondNodeCache,
      }
      this.$instance.post('/assignment/saveSecondNode',requestData)
      .then(res=>{
        if(res.data.code==0){
          this.$set(item,"addSecondNodeCache",'')
          this.$set(item,"addStatus",false)
          // item.children.push({
          //   dataList:item.children[0].dataList.map(v=>{return {...v,nodeList:[{id:null,desc:null}]}}),
          //   secondNode:res.data.info.secondNodeName,
          //   secondNodeId:res.data.info.id,
          // })
          this.getDescList()
        }
      })
      .catch(()=>{})
    },
    //市场维度修改二级节点
    editSecondNodeName(index){
      let item=this.marketDimension.list[index[0]].children[index[1]]
      this.$set(item,"canEdit",true)
      this.$set(item,"secondNodeCache",item.secondNode)
     },
    updateSecondNodeName(index){
      let item=this.marketDimension.list[index[0]].children[index[1]]
      this.$set(item,"canEdit",false)
      if(!item.secondNodeCache){
        // this.$message({message: '请输入市场监测维度二级标题。',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      let requestData={
        ...this.$store.state.basicParams,
        productId:this.productId,
        modelFreq:this.modelFreq,
        nodeName:this.marketDimension.list[index[0]].firstNode,
        secondNodeName:item.secondNodeCache,
        id:item.secondNodeId
      }
      this.$instance.post('/assignment/updateSecondNodeName',requestData)
      .then(res=>{
        if(res.data.code==0){
          // this.$set(item,"secondNode",item.secondNodeCache)
          this.getDescList()
        }else{
          this.$set(item,"secondNodeCache",item.secondNode)
        }
      })
      .catch(()=>{
        this.$set(item,"secondNodeCache",item.secondNode)
      })
    },
    //删除市场监测维度一级节点
    deleteFirstNode(index){
      this.$confirm('该监测维度及下级节点信息将被删除。', '确认删除', {
        customClass:"custom-msgbox",
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        let item=this.marketDimension.list[index]
        this.$instance.get('/assignment/deleteFirstNode',{
            params: {
            ...this.$store.state.basicParams,
            id:item.firstNodeId
            },
        })
        .then(res=>{
          if(res.data.code==0){
            this.marketDimension.list.splice(index,1)
          }
        })
        .catch(()=>{})
      })
      .catch({})
    },
    //删除市场监测维度二级节点
    deleteSecondNode(index){
      this.$confirm('该监测维度及下级节点信息将被删除。', '确认删除', {
        customClass:"custom-msgbox",
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        let item=this.marketDimension.list[index[0]].children[index[1]]
        this.$instance.get('/assignment/deleteSecondNode',{
            params: {
            ...this.$store.state.basicParams,
            id:item.secondNodeId
            },
        })
        .then(res=>{
          if(res.data.code==0){
            this.marketDimension.list[index[0]].children.splice(index[1],1)
          }
        })
        .catch(()=>{})
      })
      .catch({})
    },
    //增加事件节点
    addNodeDesc(index){
      let item=this.marketDimension.list[index[0]].children[index[1]].dataList[index[2]].nodeList
      item.push({id: null, desc: null})
    },
    activeNodeDesc(index){
      let firstNode=this.marketDimension.list[index[0]],
          secondNode=firstNode.children[index[1]],
          nodeList=secondNode.dataList[index[2]].nodeList,
          node=nodeList[index[3]]

      this.$set(node,"active",true)
    },
    saveNodeDesc(index,event){
      if(event) {
        event.target.blur()
        return false
      }
      let firstNode=this.marketDimension.list[index[0]],
          secondNode=firstNode.children[index[1]],
          nodeList=secondNode.dataList[index[2]].nodeList,
          node=nodeList[index[3]]
      this.$set(node,"active",false)
      if(!node.desc&&!node.id){
        return false
      }
      let requestData={
        ...this.$store.state.basicParams,
        productId:this.productId,
        modelFreq:this.modelFreq,
        nodeName:firstNode.firstNode,
        secondNodeName:secondNode.secondNode,
        writeCycle:secondNode.dataList[index[2]].forecastDate,
        desc:node.desc,
        id:node.id
      }
      this.$instance.post('/assignment/saveDesc',requestData)
      .then(res=>{
        if(res.data.code==0){
         if(!node.id){
          this.$set(node,"id",res.data.info.id)
         }
        }
      })
      .catch(()=>{})
    },
    //删除事件节点
    delNodeDesc(index){
      let item=this.marketDimension.list[index[0]].children[index[1]].dataList[index[2]].nodeList
      if(item[index[3]].id){
        this.$instance.get('/assignment/deleteDesc',{
          params:{
            id:item[index[3]].id
          }
        })
        .then(res=>{
          if(res.data.code==0){
            item.splice(index[3],1)
          }
        })
        .catch(()=>{})
      }else{
        item.splice(index[3],1)
      }
    },
    //获取最后提交信息
    getLastSubmissionInfo(){
      this.$instance.get('/assignment/getPublishDate',{
          params: {
            ...this.$store.state.basicParams,
            targetId:this.targetId,
            modelFreq:this.modelFreq,
          },
      })
      .then(res=>{
        if(res.data.code==0){
          this.lastSubmissionInfo=res.data.info;
        }else{
          this.lastSubmissionInfo=null;
        }
      })
      .catch(()=> {
        this.lastSubmissionInfo=null;
      }); 
    },
    //提交
    publish(){
      if(this.lastSubmissionInfo&&this.lastSubmissionInfo.createTime){
        this.checkStatus()
      }else{
        this.publishConfirm()
      }
    },
    checkStatus(){
      this.publishLoading=true
      this.$instance.get('/assignment/checkAirFlowRunStatus',{
        params:{
          ...this.$store.state.basicParams,
          publishId:this.lastSubmissionInfo.id
        }
      })
      .then(res=>{
        this.publishLoading=false
        if(res.data.code==0){
          let msg=res.data.info.status==1?'模型正在运算中，提交会重新进行模型运算，确定要提交么？':'提交会覆盖上次提交内容，并重新进行模型运算，确定要提交么？'
          this.$confirm(msg, '提示', {
            customClass:"custom-msgbox",
            confirmButtonText: '继续提交',
            cancelButtonText: '取消',
            type: 'warning'
          })
          .then(() => {
            this.publishConfirm()
          })
          .catch(()=>{})
        }
      })
      .catch(()=>{
        this.publishLoading=false
      })
    },
    publishConfirm(){
      this.publishLoading=true
      let requestData={
        ...this.$store.state.basicParams,
        productId:this.productId,
        modelFreq:this.modelFreq,
        divisorIds:this.divisors.items.map(v=>{return v.divisorId}).join(',')
      }
      this.$instance.post('/assignment/publish',requestData)
      .then(res=>{
        this.publishLoading=false
        if(res.data.code==0){
          this.$message({message: res.data.msg,type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
          this.getLastSubmissionInfo()
        }
      })
      .catch(()=>{
        this.publishLoading=false
      })
    },
  },
  computed:{
    //赋值比较是否异常-提示
    numWarning(){
      return (a,b,c)=>{
        if(a&&this.$numFormat(b)){
          return Math.abs(b/a-1) > c?true:false
        }else{
         return false
        }
      }
    },
    //因子详情走势图
    divisorChart(){
      let chartData=this.divisorData.info
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.yAxis.name=this.divisorActive.unit
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dateCycleShow})
        // opt.dataZoom.startValue=opt.xAxis.data.length-15
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            unit:this.divisorActive.unit,
            itemStyle:{
                opacity:0
            }
          }
        })
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            id:v.id,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id=='hisValue'?"solid":"dashed",
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              let item=chartData.items[val.seriesIndex]
              if(item.id!='hisValue'){
                if(!this.$numFormat(chartData.dataList[val.dataIndex]['hisValue'])||!params.some(v=>v.seriesId=='hisValue')){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
              }else{
                str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
              }
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    }
    
  },
  watch: {
    "$route.params.modelFreq" : {
     handler:function(val){
       this.modelFreq=val
       this.stepActive=0
       this.getData()
     },
     deep:true
    },
    "curProduct" : {
     handler:function(val){
      this.productId=val.productId
      this.targetId=val.targetId
      this.stepActive=0
      this.getData()
     },
     deep:true
    },
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>
.dot{
  width:5px;
  height: 5px;
  flex: 0 0 5px;
  border-radius: 100%;
  background: #F49300;
}
 .MarketMonitoringTable-title{
  @include themeify{
    background:themed('tableheadColor');
    border: themed('lineColor') solid 1px;
    border-bottom: 0;
  };
  text-align: center;
  padding: 10px;
  font-weight: bold;
 }
</style>
<style  lang="scss">
.assignment-dialog{
  .el-input-group__append, .el-input-group__prepend{
    padding: 0 10px;
    background: none;
    border-left: 0;
  }
}
</style>
